.map-window {
    max-height: 300px;
}
.map-container{
    flex-grow: 1;
}
table {
    min-width: 650;
}
.legend-container{
    position: absolute;
    margin: 10px;
}
